import * as React from "react";

import { Link } from "gatsby";
import { Slider, ResponsiveImage, CardCarrousel } from '../../../components';


export const Block = ({ block, componentKey, addRow, noGutters }) => {

	let ret = <></>;
	// console.log(block);
	let base_block_classes = block.layoutClasses ? ' ' + block.layoutClasses.trimLeft(' ') : '';

	const media_types = {
		// image types
		'gif': 'image/gif',
		'jpg': 'image/jpeg',
		'jpeg': 'image/jpeg',
		'png': 'image/png',
		'svg': 'image/svg+xml',
		'webp': 'image/webp',

		// video types
		'm4v': 'video/mp4',
		'mp4': 'video/mp4',
		'ogv': 'video/ogg',
		'ogg': 'video/ogg',
		'webv': 'video/webm',
		'webm': 'video/webm',
	}

	const media_type_from_filename = (filename) => {
		let ext = filename.indexOf('.') ? filename.substr(filename.lastIndexOf('.') + 1) : filename;
		// console.log(filename, ext, ext in media_types ? media_types[ext] : ext);

		return ext in media_types ? media_types[ext] : ext;
	}

	switch (block.blockType) {

		case 'Slider':
			ret = (
				<Slider key={componentKey}
					slides={block.slides}
					isHero={true}
					cssClasses={`${block.id}${base_block_classes}`}
				/>
			);
		break;

		case 'Listing':
			let list_items = [];
			for (let i = 0, l = block.items.length; i < l; i++) {
				let item = block.items[i];
				list_items.push(<li key={`li_${i}`}>
					{item.hasCustomIcon ? <span className="icon"><img src={item.icon.content} alt={item.title} /></span> : null}
					{item.title ? <span className="title">{item.title}</span> : null}
					{item.text ? <div className="text">{item.text}</div> : null}
				</li>);
			}
			ret = (
				block.title && block.title !== '' ?
					<div key={componentKey} className={`${block.id}${base_block_classes}`}>
						<div className="list-title">{block.title}</div>
						<ul>
							{list_items}
						</ul>
					</div>
				:
					<ul key={componentKey} className={`${block.id}${base_block_classes}`}>
						{list_items}
					</ul>
			);
		break;

		case 'TextBox':
			switch (block.textType) {
				case 'TITLE':
					ret = (
						<div key={componentKey} className={`${block.id} type-${block.textType.toLowerCase()}${base_block_classes}`}>
							<h1>{block.textContent}</h1>
						</div>
					);
				break;
				case 'SUBTITLE':
					ret = (
						<div key={componentKey} className={`${block.id} type-${block.textType.toLowerCase()}${base_block_classes}`}>
							<h2>{block.textContent}</h2>
						</div>
					);
				break;
				case 'RICHTEXT':
					ret = (
						<div key={componentKey} className={`${block.id} type-${block.textType.toLowerCase()}${base_block_classes}`} dangerouslySetInnerHTML={{__html: block.textContent}} />
					);
				break;
				case 'SHORTTEXT':
					ret = (
						<div key={componentKey} className={`${block.id} type-${block.textType.toLowerCase()}${base_block_classes}`}>
							<span dangerouslySetInnerHTML={{__html: block.textContent}} />
						</div>
					);
				break;
				case 'LONGTEXT':
					ret = (
						<div key={componentKey} className={`${block.id} type-${block.textType.toLowerCase()}${base_block_classes}`}>
							<span dangerouslySetInnerHTML={{__html: block.textContent}} />
						</div>
					);
				break;
				default:
			}
		break;

		case 'Media':
			switch (block.mediaType.toLowerCase()) {
				case 'image':
					ret = (
						<div key={componentKey} className={`${block.id} type-${block.mediaType.toLowerCase()}${base_block_classes}`}>
							<ResponsiveImage
								initialSrc={block.mediaContent[0]}
								alt={block.description}
								cssClasses="optional css-classes as-a-string"
								sources={
									media_type_from_filename(block.mediaContent[0]) === 'image/svg+xml' ?
										[
											// XXL
											{
												srcset: [
													{
														src: block.mediaContent[5],
														xd: '1x',
													}
												],
												type: 'image/svg+xml',
												media: '(min-width:1540px)'
											},

											// XL
											{
												srcset: [
													{
														src: block.mediaContent[4],
														xd: '1x',
													}
												],
												type: 'image/svg+xml',
												media: '(min-width:1200px)'
											},

											// LG
											{
												srcset: [
													{
														src: block.mediaContent[3],
														xd: '1x',
													}
												],
												type: 'image/svg+xml',
												media: '(min-width:992px)'
											},

											// MD
											{
												srcset: [
													{
														src: block.mediaContent[2],
														xd: '1x',
													}
												],
												type: 'image/svg+xml',
												media: '(min-width:768px)'
											},

											// SM
											{
												srcset: [
													{
														src: block.mediaContent[1],
														xd: '1x',
													}
												],
												type: 'image/svg+xml',
												media: '(min-width:576px)'
											},
										]
									:
										[
											// XXL
											{
												srcset: [
													{
														src: block.mediaContent[5+6],
														xd: '1x',
													},
													{
														src: block.mediaContent[5+6+6+6],
														xd: '2x',
													}
												],
												type: 'image/webp',
												media: '(min-width:1540px)'
											},
											{
												srcset: [
													{
														src: block.mediaContent[5],
														xd: '1x',
													},
													{
														src: block.mediaContent[5+6+6],
														xd: '2x',
													}
												],
												type: media_type_from_filename(block.mediaContent[5]),
												media: '(min-width:1540px)'
											},

											// XL
											{
												srcset: [
													{
														src: block.mediaContent[4+6],
														xd: '1x',
													},
													{
														src: block.mediaContent[4+6+6+6],
														xd: '2x',
													}
												],
												type: 'image/webp',
												media: '(min-width:1200px)'
											},
											{
												srcset: [
													{
														src: block.mediaContent[4],
														xd: '1x',
													},
													{
														src: block.mediaContent[4+6+6],
														xd: '2x',
													}
												],
												type: media_type_from_filename(block.mediaContent[4]),
												media: '(min-width:1200px)'
											},

											// LG
											{
												srcset: [
													{
														src: block.mediaContent[3+6],
														xd: '1x',
													},
													{
														src: block.mediaContent[3+6+6+6],
														xd: '2x',
													}
												],
												type: 'image/webp',
												media: '(min-width:992px)'
											},
											{
												srcset: [
													{
														src: block.mediaContent[3],
														xd: '1x',
													},
													{
														src: block.mediaContent[3+6+6],
														xd: '2x',
													}
												],
												type: media_type_from_filename(block.mediaContent[3]),
												media: '(min-width:992px)'
											},

											// MD
											{
												srcset: [
													{
														src: block.mediaContent[2+6],
														xd: '1x',
													},
													{
														src: block.mediaContent[2+6+6+6],
														xd: '2x',
													}
												],
												type: 'image/webp',
												media: '(min-width:768px)'
											},
											{
												srcset: [
													{
														src: block.mediaContent[2],
														xd: '1x',
													},
													{
														src: block.mediaContent[2+6+6],
														xd: '2x',
													}
												],
												type: media_type_from_filename(block.mediaContent[2]),
												media: '(min-width:768px)'
											},

											// SM
											{
												srcset: [
													{
														src: block.mediaContent[1+6],
														xd: '1x',
													},
													{
														src: block.mediaContent[1+6+6+6],
														xd: '2x',
													}
												],
												type: 'image/webp',
												media: '(min-width:576px)'
											},
											{
												srcset: [
													{
														src: block.mediaContent[1],
														xd: '1x',
													},
													{
														src: block.mediaContent[1+6+6],
														xd: '2x',
													}
												],
												type: media_type_from_filename(block.mediaContent[1]),
												media: '(min-width:576px)'
											},

											// Complement smallest image with WEBP variant
											{
												srcset: [
													{
														src: block.mediaContent[0+6],
														xd: '1x',
													},
													{
														src: block.mediaContent[0+6+6+6],
														xd: '2x',
													}
												],
												type: 'image/webp'
											},

											// Complement smallest image with 2x variant
											{
												srcset: [
													{
														src: block.mediaContent[0],
														xd: '1x',
													},
													{
														src: block.mediaContent[0+6+6],
														xd: '2x',
													}
												],
												type: media_type_from_filename(block.mediaContent[0]),
											},
										]
								}
							/>
						</div>
					);
				break;
				case 'video':
				case 'video_external':
				case 'video_youtube':
				case 'video_vimeo':
				case 'document':
				case 'embed':
				default:
					ret = (
						<div key={componentKey} className={`${block.id} type-${block.mediaType.toLowerCase()}${base_block_classes}`}>Bloco media-[{block.mediaType.toLowerCase()}] ainda não implementado</div>
					)
			}
		break;

		case 'Button':
			ret = (
				<div key={componentKey} className={`${block.id}${base_block_classes}`}>
					<Link to={block.url ? block.url : '#'}><span>{block.text}</span></Link>
				</div>
			);
		break;

		/*! UNFINISHED */
		case 'CardCarrousel':
			ret = (
				<CardCarrousel key={componentKey}
					cards={block.cards}
					cssClasses={`${block.id}${base_block_classes}`}
				/>
			);
		break;
		/*! /UNFINISHED */

		case 'BlockGroup':
			ret = (
				<div key={componentKey} className={`${block.id}${base_block_classes}`}>
					<div className={`row`}>
						{block.blocks.map((subBlock, index) => {
							return <Block
								block={subBlock}
								baseBlockClasses={`col-12`}
								index={index}
								total={block.blocks.length}
								key={`${componentKey}_sub_block_${index}`}
							/>
						})}
					</div>
				</div>
			);
		break;

		default:
			console.log('Not Yet Implemented: ', block.blockType);
	}

	return addRow ? (
		<div className={`row${noGutters ? ' g-0' : ''}`} data-title={`${block.id}`}>
			{ret}
		</div>
	) : ret;
}
