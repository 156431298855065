import React from "react"
import SlickSlider from "react-slick";
import { ResponsiveImage } from "../../../components"

/* Styles */
import "/static/assets/libs/slick-carousel-1.6.0/slick.min.css";
import "./index.css"

export const Slider = ({ slides, cssClasses, isHero }) => {

	/* console.log(slides);
	console.log(cssClasses);
	console.log(isHero); */

	var settings = {
		/* arrows: false,*/
		dots: true,
		infinite: true,
		speed: 800,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 3000,
		/* pauseOnDotsHover: true,
		pauseOnFocus: true,
		pauseOnHover: true */
		/*responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: true,
					dots: true
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					initialSlide: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		] */
	};

	return (
		<div className={'container-fluid bg-slider' + (cssClasses ? ' ' + cssClasses : '') + (isHero ? ' g-0' : '')} >

			<SlickSlider {...settings} className={(isHero ? 'g-4 ' : '') + 'slider d-flex justify-content-center align-items-start text-center'}>

				{slides.map((slide, i) => {
					// console.log(`Slide ${i + 1} in Slider Component!`, slide);
					return (
						<div key={`slide_${i}`} className="wrapper">
							<div className="h-100 d-flex justify-content-center align-items-center">
								<ResponsiveImage
									initialSrc={slide.media.mediaContent[0]}
									alt={slide.title}
									cssClasses="optional css-classes as-a-string"
									sources={[
										// XXL
										{
											srcset: [
												{
													src: slide.media.mediaContent[5+6],
													xd: "1x",
												},
												/* {
													src: "https://via.placeholder.com/3840x2160.webp/f9f900?text=WEBP+Image+XXL@2x",
													xd: "2x",
												} */
											],
											type: "image/webp",
											media: "(min-width:1540px)"
										},
										{
											srcset: [
												{
													src: slide.media.mediaContent[5],
													xd: "1x",
												},
												/* {
													src: "https://via.placeholder.com/3840x2160.png/f9f900?text=PNG+Image+XXL@2x",
													xd: "2x",
												} */
											],
											type: "image/png",
											media: "(min-width:1540px)"
										},

										// XL
										{
											srcset: [
												{
													src: slide.media.mediaContent[4+6],
													xd: "1x",
												},
												/* {
													src: "https://via.placeholder.com/3080x1800.webp/f9f900?text=WEBP+Image+XL@2x",
													xd: "2x",
												} */
											],
											type: "image/webp",
											media: "(min-width:1200px)"
										},
										{
											srcset: [
												{
													src: slide.media.mediaContent[4],
													xd: "1x",
												},
												/* {
													src: "https://via.placeholder.com/3080x1800.png/f9f900?text=PNG+Image+XL@2x",
													xd: "2x",
												} */
											],
											type: "image/png",
											media: "(min-width:1200px)"
										},

										// LG
										{
											srcset: [
												{
													src: slide.media.mediaContent[3+6],
													xd: "1x",
												},
												/* {
													src: "https://via.placeholder.com/2400x2400.webp/f9f900?text=WEBP+Image+LG@2x",
													xd: "2x",
												} */
											],
											type: "image/webp",
											media: "(min-width:992px)"
										},
										{
											srcset: [
												{
													src: slide.media.mediaContent[3],
													xd: "1x",
												},
												/* {
													src: "https://via.placeholder.com/2400x2400.png/f9f900?text=PNG+Image+LG@2x",
													xd: "2x",
												} */
											],
											type: "image/png",
											media: "(min-width:992px)"
										},

										// MD
										{
											srcset: [
												{
													src: slide.media.mediaContent[2+6],
													xd: "1x",
												},
												/* {
													src: "https://via.placeholder.com/1984x1984.webp/f9f900?text=WEBP+Image+MD@2x",
													xd: "2x",
												} */
											],
											type: "image/webp",
											media: "(min-width:768px)"
										},
										{
											srcset: [
												{
													src: slide.media.mediaContent[2],
													xd: "1x",
												},
												/* {
													src: "https://via.placeholder.com/1984x1984.png/f9f900?text=PNG+Image+MD@2x",
													xd: "2x",
												} */
											],
											type: "image/png",
											media: "(min-width:768px)"
										},

										// SM
										{
											srcset: [
												{
													src: slide.media.mediaContent[1+6],
													xd: "1x",
												},
												/* {
													src: "https://via.placeholder.com/1536x1536.webp/f9f900?text=WEBP+Image+SM@2x",
													xd: "2x",
												} */
											],
											type: "image/webp",
											media: "(min-width:576px)"
										},
										{
											srcset: [
												{
													src: slide.media.mediaContent[1],
													xd: "1x",
												},
												/* {
													src: "https://via.placeholder.com/1536x1536.png/f9f900?text=PNG+Image+SM@2x",
													xd: "2x",
												} */
											],
											type: "image/png",
											media: "(min-width:576px)"
										},

										// Complement smallest image with WEBP variant
										{
											srcset: [
												{
													src: slide.media.mediaContent[0+6],
													xd: "1x",
												},
												/* {
													src: "https://via.placeholder.com/750x750.webp/f9f900?text=WEBP+Image+XS@2x",
													xd: "2x",
												} */
											],
											type: "image/webp"
										},

									]}
								/>
								<div className="info-holder container-fluid">
									<div className="row">
										<h1 className="col-10 offset-1 col-md-8 col-lg-7 offset-lg-2 title">{slide.title}</h1>
										<h2 className="col-10 offset-1 col-md-8 col-lg-7 offset-lg-2 subtitle">{slide.subtitle}</h2>
									</div>
								</div>
							</div>
						</div>
					);
				})}

			</SlickSlider>

		</div>
	)
}
