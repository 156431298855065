import React from "react";
import { LazyLoad } from "../../../components"

/* Styles */
import "./index.css"

export const ResponsiveImage = ({ initialSrc, initialSrcSet, alt, sources, cssClasses, ...otherArgs }) => {
	if (!initialSrc) {
		return <>initialSrc is undefined</>;
	}

	let sanitizedImageSrc = initialSrc.replace(" ", "%20");

	let tagSources = [];
	if (sources !== undefined && "length" in sources) {
		for (let i = 0, l = sources.length; i < l; i++) {
			let source = sources[i];
			let src = null,
				srcSet = null,
				media = null,
				type = null;

			if ("src" in source) {
				src = source.src;
			}
			if ("srcset" in source && "length" in source.srcset) {
				srcSet = [];
				for (let ii = 0, ll = source.srcset.length; ii < ll; ii++) {
					let srcsetItem = source.srcset[ii];
					if (!("src" in srcsetItem) || !srcsetItem.src) {
						continue;
					}
					srcSet.push(srcsetItem.src + ("xd" in srcsetItem ? " " + srcsetItem.xd : ""));
				}
				srcSet = srcSet.join(", ");
			}
			if ("media" in source) {
				media = source.media;
			}
			if ("type" in source) {
				type = source.type;
			}
			if (src || srcSet) {
				tagSources.push(<source key={`sanitizedImageSrc_${i}`} src={src} srcSet={srcSet} media={media} type={type} />);
			}
		}
	}

	return (
		<LazyLoad key={sanitizedImageSrc} delayedCall={true} partialVisibility={true} once>
			{ ({ isVisible }) =>
				<>
					{ isVisible ?
						<picture className="responsive">

							{tagSources}

							<img
								className={cssClasses}
								alt={alt}
								srcSet={initialSrcSet}
								src={initialSrc}
							/>
						</picture>
					:
						<picture className="responsive">

							{tagSources.length > 0 ? tagSources[tagSources.length - 1] : null}

							<img
								className={cssClasses}
								alt={alt}
								src={initialSrc}
							/>
						</picture>
					}
				</>
			}
		</LazyLoad>
	)
}
