import React, { useState, useEffect } from "react";
import { StaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { Header, PreFooter, Footer, CookieConsent } from "../../../components";
import { motion } from "framer-motion";

/* Component Styles */
import "./index.css";

export const Layout = ({
	pageTitle = false,
	children,
	hasPreFooter = true,
	headerforceColor2 = true,
}) => {
	// determine if page has scrolled and if the view is on mobile
	const [scrolled, setScrolled] = useState(false);

	// change state on scroll
	useEffect(() => {
		const handleScroll = () => {
			const isScrolled = window.scrollY > 80;
			if (isScrolled !== scrolled) {
				setScrolled(!scrolled);
			}
		};

		document.addEventListener("scroll", handleScroll, {
			passive: true,
		});

		return () => {
			// clean up the event handler when the component unmounts
			document.removeEventListener("scroll", handleScroll);
		};
	}, [scrolled]);

	return (
		<StaticQuery
			query={graphql`
				query SiteTitleQuery {
					site {
						siteMetadata {
							title
							menuLinks {
								name
								link
								subMenu {
									name
									link
									icon
								}
							}
						}
					}
				}
			`}
			render={(data) => (
				<React.Fragment>
					<Helmet
						title={(pageTitle ? pageTitle + ' - ' : '') + data.site.siteMetadata.title}
						meta={[
							{
								name: "description",
								content: data.site.siteMetadata.description,
							},
							{
								name: "keywords",
								content:
									data.site.siteMetadata.title + ", keywords",
							},
						]}
					>
						{/* Global Styles */}
						{/* <link rel="preload" as="style" onLoad="this.onload=null;this.rel='stylesheet'" href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.1/dist/css/bootstrap.min.css" type="text/css" crossOrigin="anonymous" />
						<noscript>{`<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.1/dist/css/bootstrap.min.css" rel="stylesheet" type="text/css" crossOrigin="anonymous" />`}</noscript>*/}
						<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.1/dist/css/bootstrap.min.css" rel="stylesheet" type="text/css" crossOrigin="anonymous" />

						{/* <link rel="preload" as="style" onLoad="this.onload=null;this.rel='stylesheet'" href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.1/dist/css/bootstrap-utilities.min.css" type="text/css" crossOrigin="anonymous" />
						<noscript>{`<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.1/dist/css/bootstrap-utilities.min.css" rel="stylesheet" type="text/css" crossOrigin="anonymous" />`}</noscript> */}
						<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.1/dist/css/bootstrap-utilities.min.css" rel="stylesheet" type="text/css" crossOrigin="anonymous" />

						{/* <link rel="preload" as="style" onLoad="this.onload=null;this.rel='stylesheet'" href="https://fonts.googleapis.com/css?family=Montserrat:300,400,400i,700,700i&amp;display=fallback" type="text/css" />
						<noscript>{`<link href="https://fonts.googleapis.com/css?family=Montserrat:300,400,400i,700,700i&amp;display=fallback" rel="stylesheet" />`}</noscript> */}
						<link href="https://fonts.googleapis.com/css?family=Montserrat:300,400,400i,700,700i&amp;display=fallback" rel="stylesheet" />

						{/* <link rel="preload" as="style" onLoad="this.onload=null;this.rel='stylesheet'" href="/assets/css/global.css" type="text/css" />
						<noscript>{`<link href="/assets/css/global.css" rel="stylesheet" type="text/css" />`}</noscript> */}
						<link href="/assets/css/global.css" rel="stylesheet" type="text/css" />

						{/* <link rel="preload" as="style" onLoad="this.onload=null;this.rel='stylesheet'" href="/assets/css/index.css" type="text/css" />
						<noscript>{`<link href="/assets/css/index.css" rel="stylesheet" type="text/css" />`}</noscript> */}
						<link href="/assets/css/index.css" rel="stylesheet" type="text/css" />

						{/* <link rel="preload" as="style" onLoad="this.onload=null;this.rel='stylesheet'" href="/assets/css/services.css" type="text/css" />
						<noscript>{`<link href="/assets/css/services.css" rel="stylesheet" type="text/css" />`}</noscript> */}
						<link href="/assets/css/services.css" rel="stylesheet" type="text/css" />

						{/* <link rel="preload" as="style" onLoad="this.onload=null;this.rel='stylesheet'" href="/assets/css/about.css" type="text/css" />
						<noscript>{`<link href="/assets/css/about.css" rel="stylesheet" type="text/css" />`}</noscript> */}
						<link href="/assets/css/about.css" rel="stylesheet" type="text/css" />

						{/* <link rel="preload" as="style" onLoad="this.onload=null;this.rel='stylesheet'" href="/assets/css/contacts.css" type="text/css" />
						<noscript>{`<link href="/assets/css/contacts.css" rel="stylesheet" type="text/css" />`}</noscript> */}
						<link href="/assets/css/contacts.css" rel="stylesheet" type="text/css" />

						{/* <link rel="preload" as="style" onLoad="this.onload=null;this.rel='stylesheet'" href="/assets/css/404.css" type="text/css" />
						<noscript>{`<link href="/assets/css/404.css" rel="stylesheet" type="text/css" />`}</noscript> */}
						<link href="/assets/css/404.css" rel="stylesheet" type="text/css" />

					</Helmet>

					<Header
						siteTitle={data.site.siteMetadata.title}
						menuLinks={data.site.siteMetadata.menuLinks}
						hasPreFooter={hasPreFooter}
						isScrolled={scrolled}
						forceColor2={headerforceColor2}
						logo_h_color_1="/assets/img/logi_horiz_white.svg"
						logo_v_color_1="/assets/img/logi_vert_white.svg"
						logo_h_color_2="/assets/img/logi_horiz_red.svg"
						logo_v_color_2="/assets/img/logi_vert_red.svg"
					/>

					<motion.main
						//initial={{ opacity: 0, x: -200 }}
						//animate={{ opacity: 1, x: 0 }}
						//exit={{ opacity: 0, x: 200 }}
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						transition={{
							type: "spring",
							mass: 0.35,
							stiffness: 75,
							duration: 0.3
						}}
					>
						{children}
					</motion.main>

					{hasPreFooter ? <PreFooter /> : null}

					<Footer />

					<CookieConsent />
				</React.Fragment>
			)}
		/>
	);
};
