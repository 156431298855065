import React, { useEffect, useState, useRef } from 'react';
import { Link } from "gatsby"
import PropTypes from "prop-types"

/* Styles */
import "./index.css"


export const Header = ({ siteTitle, menuLinks, isScrolled, forceColor2, logo_h_color_1, logo_h_color_2, logo_v_color_1, logo_v_color_2 }) => {

	const [openedMenu, setOpenedMenu] = useState(true);

	function toggle_menu(evt) {
		var targetElement = evt.target || evt.srcElement;
		//console.log(targetElement.id);
		//console.log(window.outerWidth);

		if (targetElement.id === 'nav_submenu_xs' && !(window.outerWidth >= 992)) {
			return;
		}

		//console.log('toggle_menu', openedMenu);
		setOpenedMenu(!openedMenu);

		if (openedMenu) {
			document.body.classList.add("no-scroll");
			document.body.classList.add("menu-open");
		} else {
			document.body.classList.remove("no-scroll");
			document.body.classList.remove("menu-open");
			document.querySelector('#nav_submenu_xs').checked = false;
		}
	}


	let latestWidth = useRef(0);

	useEffect(_ => {
		latestWidth.current = window.outerWidth;

		const handleResize = evt => {
			// console.log('useEffect onresize', latestWidth.current, window.outerWidth);
			if (window.outerWidth === latestWidth.current) {
				// console.log('-- no width change!');
				return;
			}
			latestWidth.current = window.outerWidth;

			setOpenedMenu(true);
			document.body.classList.remove("no-scroll");
			document.querySelector('#nav_submenu_xs').checked = false;
		}

		window.addEventListener('resize', handleResize);

		/* return () => {
			// clean up the event handler when the component unmounts
			document.body.classList.remove("no-scroll");
			document.body.classList.remove("menu-open");
			document.removeEventListener('resize', handleResize);
		}; */
	});

	useEffect(_ => {
		if (isScrolled) {
			document.body.classList.add("on-scroll");
		} else {
			document.body.classList.remove("on-scroll");
		}
	}, [isScrolled]);

	useEffect(() => {
		return () => {
			document.body.classList.remove("no-scroll");
			document.body.classList.remove("menu-open");
			document.querySelector('#nav_submenu_xs').checked = false;
		};
	}, []);

	/* console.log({
		isScrolled,
		openedMenu,
		forceColor2,
	}) */

	return (
		<>
			<input type="checkbox" id="nav_menu_xs" name="nav_menu_xs" className="d-none" autoComplete="off" onChange={ toggle_menu }/>
			<header className={ 'container-fluid bg-header fixed' + (forceColor2 ? ' force-color-2' : '') }>
			<div className="header row justify-content-center align-items-center">

				<div className="col-6 offset-3 col-lg-2 offset-lg-0 text-left">
					<Link className={ 'logo' + (isScrolled ? ' on-scroll' : '') } to="/">
						<picture>
							<source srcSet={((!isScrolled || !openedMenu) && (!forceColor2 || !openedMenu) ? logo_v_color_1 : logo_v_color_2)} media="(min-width:992px)" />
							<img src={ !openedMenu ? logo_h_color_1 : (!isScrolled && !forceColor2 ? logo_h_color_1 : logo_h_color_2) } alt={siteTitle} />
						</picture>
					</Link>
				</div>

				<nav className="col-3 col-lg-10 text-end text-lg-center">

					<label htmlFor="nav_menu_xs" className="menu-icon d-lg-none" aria-label="Menu">
						<span className="menu-icon-line" style={ (!openedMenu ? {background: '#fff'} : (forceColor2 ? {background: '#323335'} : {})) }></span>
						<span className="menu-icon-line" style={ (!openedMenu ? {background: '#fff'} : (forceColor2 ? {background: '#323335'} : {})) }></span>
						<span className="menu-icon-line" style={ (!openedMenu ? {background: '#fff'} : (forceColor2 ? {background: '#323335'} : {})) }></span>
					</label>

					<div className="menu row justify-content-center align-items-center flex-s">
						<ul className="col-12 col-md-8 col-lg-12 d-lg-flex justify-content-lg-end align-items-lg-center">
							{menuLinks.map( (link, i) => {
								let classes = [];

								// if there is a submenu
								if (link.subMenu) {
									classes.push('has-submenu');
								}

								// if last item
								if (i + 1 === menuLinks.length) {
									classes.push('mb-5 mb-lg-0');
								}

								return link.link !== '/' ?
									<li key={link.name} className={classes.join(' ')}>
										{link.subMenu ?
											<>
												<input type="checkbox" id="nav_submenu_xs" name="nav_submenu_xs" className="d-none" autoComplete="off" onClick={ toggle_menu } />
												<label className="submenu-a" htmlFor="nav_submenu_xs" aria-label="Submenu">
													<span>{link.name}</span>
												</label>

												<div className="submenu container-fluid d-flex flex-column justify-content-center">

													<div className="row top">
														<label className="back-btn col-1 offset-md-2" htmlFor="nav_submenu_xs" aria-label="Submenu"><span></span></label>
														<div className="title col-10 col-md-7 col-lg-10">{link.name}</div>
													</div>

													<div className="row list">
														<div className="col-1 offset-md-2 offset-lg-0"></div>
														<ul className="col-10 col-md-7 col-lg-10 pb-3">
														{link.subMenu.map( (link, i) => (
															<li key={link.name}>
																<Link to={link.link} onClick={ toggle_menu }>{link.icon ? <img className="icon" src={link.icon} alt={link.name} /> : null }<span className="text">{link.name}</span></Link>
															</li>
														))}
														</ul>
													</div>
												</div>
											</>
										:
										<Link to={link.link} onClick={ _ => {if (latestWidth.current < 992 && !openedMenu) {toggle_menu(_);}} }>{link.name}</Link>
										}
									</li>
								: null
							})}

							{/* <li className="locale-picker d-lg-block">
								<div className="locales-wrapper">
									<label>
										<input type="radio" name="locale" value="pt" onChange={e => {}} checked={true} hidden />
										<span>PT</span></label>
									<label>
										<input type="radio" name="locale" value="en" onChange={e => {}} hidden />
										<span>EN</span>
									</label>
								</div>
							</li> */}

							<li className="d-lg-none">
								<a href="tel:+351214350244" className="contact">
									<img className="icon" src="/assets/img/icon_phone.svg" alt="Contacto" /> +351 214 350 244
								</a>
							</li>

							<li className="social-networks d-lg-none">
								<a href="https://www.linkedin.com/" target="_blank" rel="noreferrer">
									<img src="/assets/img/sn_li.svg" alt="LinkedIn" />
								</a>
								<a href="https://www.instagram.com/" target="_blank" rel="noreferrer">
									<img src="/assets/img/sn_ig.svg" alt="Instagram" />
								</a>
								<a href="https://www.facebook.com/" target="_blank" rel="noreferrer">
									<img src="/assets/img/sn_fb.svg" alt="Facebook" />
								</a>
							</li>

							<li className="d-none d-lg-block">
								<a href="tel:+351214350244" className="contact">
									<span className="icon-holder">
										<img className="icon" src="/assets/img/icon_phone.svg" alt="Contacto" />
									</span>
									<span className="text"> +351 214 350 244</span>
								</a>
							</li>

						</ul>
					</div>

				</nav>

			</div>
		</header>
		</>
	)
}

Header.propTypes = {
	siteTitle: PropTypes.string,
}

Header.defaultProps = {
	siteTitle: ``,
}
