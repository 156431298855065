import React from "react"
import { Link } from "gatsby"

/* Styles */
import "./index.css"

export const Footer = () => {
	const deploy_year = 2020;
	const this_year = (new Date()).getFullYear();
	return (
		<footer className="container-fluid bg-footer">

			<div className="footer py-3 row">

				<nav className="col-10 offset-1 mt-5 mb-4 col-md-9 offset-md-0 mt-md-0 mb-md-0 col-lg-8 offset-lg-1">
					<ul className="menu d-flex flex-column align-items-md-center flex-md-row w-100 justify-content-md-start">
						<li className="">
							<Link className="about" to="/about">
								Quem Somos
							</Link>
						</li>
						{/* <li className="">
							<Link className="services" to="#">
								Soluções de Negócio
							</Link>
						</li> */}
						<li className="">
							<Link className="contacts" to="/contacts">
								Contactos
							</Link>
						</li>
					</ul>
				</nav>

				{/* <nav className="social-networks col-10 offset-1 mb-4 col-md-3 offset-md-0 mb-md-0 d-md-flex align-content-md-center justify-content-md-end col-lg-2">
					<a href="https://www.linkedin.com/" target="_blank" rel="noreferrer">
						<img src="/assets/img/sn_li.svg" alt="LinkedIn" />
					</a>
					<a href="https://www.instagram.com/" target="_blank" rel="noreferrer">
						<img src="/assets/img/sn_ig.svg" alt="Instagram" />
					</a>
					<a href="https://www.facebook.com/" target="_blank" rel="noreferrer">
						<img src="/assets/img/sn_fb.svg" alt="Facebook" />
					</a>
				</nav> */}

				<nav className="col-10 offset-1 text-md-start col-md-6 offset-md-0 d-md-flex col-lg-5 offset-lg-1">
					<ul className="menu d-flex flex-column align-items-md-center flex-md-row w-100 justify-content-md-start">
						<li className="">
							<Link className="privacy-policy" to="/privacy-policy-and-cookies">
								Política de Privacidade e Cookies
							</Link>
						</li>
					</ul>
				</nav>

				<div className="col-10 offset-1 col-md-6 offset-md-0 offset-md-0 text-md-end mb-5 d-md-flex align-items-md-center justify-content-md-end mb-md-0 col-lg-5">
					<span>&copy; { deploy_year + (this_year > deploy_year ? '-' + this_year : '') } Logiphorika. All rights reserved. <br className="d-lg-none" />Carefully crafted by <a href="https://www.adhesivebrands.pt" target="_blank" rel="noreferrer">Adhesive / Brand+Digital</a></span>
				</div>

			</div>

		</footer>
	)
}
