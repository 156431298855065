import React from "react"
import SlickSlider from "react-slick";
import { ResponsiveImage } from "../../../components"

/* Styles */
import "/static/assets/libs/slick-carousel-1.6.0/slick.min.css";
import "./index.css"

export const CardCarrousel = ({ cards, cssClasses }) => {

	/* console.log(slides);
	console.log(cssClasses); */

	var settings = {
		/* arrows: false,*/
		dots: true,
		infinite: true,
		speed: 800,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 3000,
		/* pauseOnDotsHover: true,
		pauseOnFocus: true,
		pauseOnHover: true */
		/*responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: true,
					dots: true
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					initialSlide: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		] */
	};

	return (
		<div className={'container-fluid p-0 bg-slider' + (cssClasses ? ' ' + cssClasses : '')} >

			<SlickSlider {...settings} className="slider card-carrousel d-flex justify-content-center align-items-start text-center">

				{cards.map((card, i) => {
					// console.log(`Card ${i + 1} in CardCarrousel Component!`, card);
					return (
						<div key={`card_${i}`} className="wrapper">
							<div className="h-100 d-flex justify-content-center align-items-center">
								<ResponsiveImage
									initialSrc={card.media.mediaContent[0]}
									alt={card.title}
									cssClasses="optional css-classes as-a-string"
									sources={[
										// XXL
										{
											srcset: [
												{
													src: card.media.mediaContent[5+6],
													xd: "1x",
												},
												/* {
													src: "https://via.placeholder.com/3840x2160.webp/f9f900?text=WEBP+Image+XXL@2x",
													xd: "2x",
												} */
											],
											type: "image/webp",
											media: "(min-width:1540px)"
										},
										{
											srcset: [
												{
													src: card.media.mediaContent[5],
													xd: "1x",
												},
												/* {
													src: "https://via.placeholder.com/3840x2160.png/f9f900?text=PNG+Image+XXL@2x",
													xd: "2x",
												} */
											],
											type: "image/png",
											media: "(min-width:1540px)"
										},

										// XL
										{
											srcset: [
												{
													src: card.media.mediaContent[4+6],
													xd: "1x",
												},
												/* {
													src: "https://via.placeholder.com/3080x1800.webp/f9f900?text=WEBP+Image+XL@2x",
													xd: "2x",
												} */
											],
											type: "image/webp",
											media: "(min-width:1200px)"
										},
										{
											srcset: [
												{
													src: card.media.mediaContent[4],
													xd: "1x",
												},
												/* {
													src: "https://via.placeholder.com/3080x1800.png/f9f900?text=PNG+Image+XL@2x",
													xd: "2x",
												} */
											],
											type: "image/png",
											media: "(min-width:1200px)"
										},

										// LG
										{
											srcset: [
												{
													src: card.media.mediaContent[3+6],
													xd: "1x",
												},
												/* {
													src: "https://via.placeholder.com/2400x2400.webp/f9f900?text=WEBP+Image+LG@2x",
													xd: "2x",
												} */
											],
											type: "image/webp",
											media: "(min-width:992px)"
										},
										{
											srcset: [
												{
													src: card.media.mediaContent[3],
													xd: "1x",
												},
												/* {
													src: "https://via.placeholder.com/2400x2400.png/f9f900?text=PNG+Image+LG@2x",
													xd: "2x",
												} */
											],
											type: "image/png",
											media: "(min-width:992px)"
										},

										// MD
										{
											srcset: [
												{
													src: card.media.mediaContent[2+6],
													xd: "1x",
												},
												/* {
													src: "https://via.placeholder.com/1984x1984.webp/f9f900?text=WEBP+Image+MD@2x",
													xd: "2x",
												} */
											],
											type: "image/webp",
											media: "(min-width:768px)"
										},
										{
											srcset: [
												{
													src: card.media.mediaContent[2],
													xd: "1x",
												},
												/* {
													src: "https://via.placeholder.com/1984x1984.png/f9f900?text=PNG+Image+MD@2x",
													xd: "2x",
												} */
											],
											type: "image/png",
											media: "(min-width:768px)"
										},

										// SM
										{
											srcset: [
												{
													src: card.media.mediaContent[1+6],
													xd: "1x",
												},
												/* {
													src: "https://via.placeholder.com/1536x1536.webp/f9f900?text=WEBP+Image+SM@2x",
													xd: "2x",
												} */
											],
											type: "image/webp",
											media: "(min-width:576px)"
										},
										{
											srcset: [
												{
													src: card.media.mediaContent[1],
													xd: "1x",
												},
												/* {
													src: "https://via.placeholder.com/1536x1536.png/f9f900?text=PNG+Image+SM@2x",
													xd: "2x",
												} */
											],
											type: "image/png",
											media: "(min-width:576px)"
										},

										// Complement smallest image with WEBP variant
										{
											srcset: [
												{
													src: card.media.mediaContent[0+6],
													xd: "1x",
												},
												/* {
													src: "https://via.placeholder.com/750x750.webp/f9f900?text=WEBP+Image+XS@2x",
													xd: "2x",
												} */
											],
											type: "image/webp"
										},

									]}
								/>
								<div className="info-holder container-fluid">
									<div className="row">
										<h1 className="title">{card.title}</h1>
										<h2 className="subtitle">{card.subtitle}</h2>
										<p className="text">{card.text}</p>
									</div>
								</div>
							</div>
						</div>
					);
				})}

			</SlickSlider>

		</div>
	)
}
