import React from "react";
import { Link } from "gatsby";


/* Styles */
import "./index.css"

export const PreFooter = () => {
	return (
		<div className="bg-pre-footer container-fluid" >
			<div className="pre-footer row">
				<div className="d-flex col-10 offset-1 col-md-5 col-lg-4 offset-lg-2">
					<h2 className="pb-4 align-self-center">
						<strong>
							Fale connosco <br />e conheça <br />todos os nossos serviços/produtos
						</strong>
					</h2>
				</div>
				<div className="col-10 offset-1 col-md-4 col-lg-3">
					<p>
						Temos uma equipa sempre disponível para o
						ajudar a encontrar a melhor solução para as
						suas necessidades, desde o conceito ao
						pós-venda.
					</p>

					<div className="text-center my-2 pt-4">
						<Link to="/contacts" className="contact-button">
							Contacte-nos
						</Link>
					</div>
				</div>
			</div>
		</div>
	)
}
