import React from "react"
import { Link } from "gatsby"

/* Styles */
import "./index.css"

export const CookieConsent = () => {


	const handleCookieConsentModal = (e) => {
		//var $el = e.currentTarget.parentNode.parentNode.parentNode.id;
		//console.log($el, '=> handleCookieConsentModal()');

		// var $modal = document.querySelector('.cookie-consent-modal');
		// console.log( $modal );
		// $modal.classList.toggle("d-none");
	}


	return (
		<div id="cookies">
			<input type="checkbox" id="cookie_consent_accept" name="cookie_consent_accept" className="d-none" autoComplete="off" onChange={ handleCookieConsentModal } />
			<input type="checkbox" id="cookie_consent" name="cookie_consent" className="d-none" autoComplete="off" onChange={ handleCookieConsentModal } />
			<input type="checkbox" id="open_cookie_modal" name="open_cookie_modal" className="d-none" autoComplete="off" />

			<div className="cookie-consent p-3 d-flex justify-content-around justify-content-sm-center align-items-center">
				<div className="icon-cookie mx-1">
					<span role="img" aria-label="Cookie Consent">🍪</span>
				</div>
				<div className="txt mx-1">
					{/* This website use cookies for a better experience.<br/>
					<label htmlFor="open_cookie_modal" aria-label="Learn more" className="learn-more">Learn more</label> */}
					Usamos cookies para uma melhor experiência.<br/>
					<label htmlFor="open_cookie_modal" aria-label="Learn more" className="learn-more">Saiba mais</label>
					{/* <Link to="#" className="learn-more" onClick={handleCookieConsentModal}>Learn more</Link> */}
				</div>
				<label htmlFor="cookie_consent" aria-label="OK" className="btn mx-1">OK</label>
				{/*<Link to="#" className="btn mx-1">OK</Link>*/}
			</div>

			<div className="cookie-consent-modal d-none">
				<div className="modal-container container">
					<div className="row justify-content-center align-items-center h-100">
						{/*<div className="col-12 align-self-start text-end">
							<label htmlFor="open_cookie_modal" className="btn close" aria-label="Close">X</label>
						</div>*/}
						<div className="col-12 cookie-desc-wrapper">
							<ul className="cookie-desc-list">
								<li className="cookie-desc">
									<div className="row justify-content-center align-items-center">
										<div className="col-8 col-sm-10">
											<h5>Cookies Necessários</h5>
											<p>Essenciais na navegação, acesso a recursos e funcionamento do website, que não guardam dados pessoais. Estes cookies não podem ser desactivados.</p>
										</div>
										<div className="col-4 col-sm-2 d-flex justify-content-center">
											<input type="checkbox" id="switch_01" name="switch_01" className="toggle d-none" defaultChecked />
											<div className="toggle-btn opacity-50">Toggle</div>
										</div>
									</div>
								</li>
								<li className="cookie-desc">
									<div className="row justify-content-center align-items-center">
										<div className="col-8 col-sm-10">
											<h5>Cookies Funcionais</h5>
											<p>Fornecem uma experiência mais personalizada no website e permitem guardar escolhas que faz ao usá-lo (ex.: idioma ou sessão).</p>
										</div>
										<div className="col-4 col-sm-2 d-flex justify-content-center">
											<input type="checkbox" id="switch_02" name="switch_02" className="toggle d-none" defaultChecked />
											<label htmlFor="switch_02" className="toggle-btn">Toggle</label>
										</div>
									</div>
								</li>
								<li className="cookie-desc">
									<div className="row justify-content-center align-items-center">
										<div className="col-8 col-sm-10">
											<h5>Cookies de Análise e Desempenho</h5>
											<p>Recolhem dados sobre como os visitantes utilizam o website e ajudam-nos a perceber preferências para oferecer um melhor serviço.</p>
										</div>
										<div className="col-4 col-sm-2 d-flex justify-content-center">
											<input type="checkbox" id="switch_03" name="switch_03" className="toggle d-none" defaultChecked />
											<label htmlFor="switch_03" className="toggle-btn">Toggle</label>
										</div>
									</div>
								</li>
								<li className="cookie-desc">
									<div className="row justify-content-center align-items-center">
										<div className="col-8 col-sm-10">
											<h5>Cookies de Terceiros</h5>
											<p>Usados por plataformas terceiras e apenas para funcionalidades específicas, como a a visualização de um vídeo do Vimeo ou Youtube.</p>
										</div>
										<div className="col-4 col-sm-2 d-flex justify-content-center">
											<input type="checkbox" id="switch_04" name="switch_04" className="toggle d-none" defaultChecked />
											<label htmlFor="switch_04" className="toggle-btn">Toggle</label>
										</div>
									</div>
								</li>
								<li className="cookie-desc">
									<div className="row justify-content-center align-items-center">
										<div className="col-12 privacy-policy">
											<h5>Mais informações</h5>
											<p>Para saber mais sobre a nossa política de dados e as suas escolhas, entre em contacto ou leia <Link to="#" className="">aqui</Link> a nossa Política de Privacidade.</p>
										</div>
									</div>
								</li>

							</ul>
						</div>
						<div className="col-12 align-self-end text-center">
							{/*<div className="btn consent close">OK</div>*/}
							<label htmlFor="cookie_consent_accept" className="btn close" aria-label="OK">Aceitar</label>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
